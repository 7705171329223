import { EnvironmentType, NavigationConfigType, NavigationV2 } from "best-common-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dplLogo from "../../assets/prospect-link-logo.png";
import { RouteConstants } from "../../constants/RouteConstants";
import { TranslationConstants } from "../../constants/TranslationConstants";
import { useRoles } from "../../contexts/RolesContext";
import { useUser } from "../../contexts/UserContext";
import ChangeLanguageModal from "./ChangeLanguageModal";

const env = window.MLBBest.envVariables.ENV as EnvironmentType;

const Navigation: React.FC = () => {
  const { t } = useTranslation([TranslationConstants.NAVIGATION]);
  const { logout, loggedIn, userInfo } = useUser();
  const { isIntlResourceCreator, isInviter, isIntlAdmin } = useRoles();
  const [showChangeLanguage, setShowChangeLanguage] = useState<boolean>(false);

  const config: NavigationConfigType = {
    env: env,
    builds: [],
    title: "IPL",
    displayEnvIndicator: env !== "prod",
    loggedIn: loggedIn,
    endLogo: {
      logo: dplLogo,
      visible: true,
    },
    username: userInfo?.username,
    tabletSupport: false,
    usernameDropdownLinks: [
      {
        name: t("navigation:changeLanguage.name"),
        onClick: () => setShowChangeLanguage(true),
      },
      {
        name: t("navigation:signOut"),
        onClick: logout,
      },
    ],
    navigationLinks: [
      {
        name: t("navigation:prospects"),
        icon: "fa-user-circle",
        iconCategory: "far",
        to: RouteConstants.PROSPECTS,
        quickLink: true,
        visible: true,
      },
      {
        name: t("navigation:invitations"),
        icon: "fa-envelope",
        iconCategory: "far",
        to: RouteConstants.INVITATIONS,
        visible: isInviter,
        quickLink: true,
      },
      {
        name: t("navigation:tutorials"),
        icon: "fa-play-circle",
        iconCategory: "far",
        to: RouteConstants.TUTORIALS,
        quickLink: true,
      },
      {
        name: t("navigation:reports"),
        icon: "fa-file",
        iconCategory: "far",
        to: RouteConstants.REPORTS,
        quickLink: true,
      },
      {
        name: t("navigation:admin"),
        icon: "fa-cog",
        iconCategory: "fas",
        quickLink: false,
        displayName: true,
        visible: isIntlAdmin || isIntlResourceCreator,
        subLinks: [
          {
            name: t("navigation:help"),
            icon: "fa-question-circle",
            iconCategory: "far",
            to: RouteConstants.HELP,
            quickLink: true,
            visible: isIntlResourceCreator,
          },
          {
            name: t("navigation:consentForm"),
            icon: "fa-file-alt",
            iconCategory: "far",
            to: RouteConstants.CONSENT_FORM,
            quickLink: true,
            visible: isIntlAdmin,
          },
          {
            name: t("navigation:tasks"),
            icon: "fas fa-tasks",
            iconCategory: "far",
            to: RouteConstants.TASKS,
            quickLink: true,
            visible: isIntlAdmin,
          },
          {
            name: t("navigation:settings"),
            icon: "fa-cog",
            iconCategory: "fas",
            to: RouteConstants.SETTINGS,
            quickLink: true,
            visible: isIntlAdmin,
          },
        ],
      },
      {
        name: t("navigation:trainers"),
        icon: "fa-users",
        iconCategory: "fas",
        quickLink: false,
        displayName: true,
        visible: isIntlAdmin,
        subLinks: [
          {
            name: t("navigation:trainerInfo"),
            icon: "fa-id-card",
            iconCategory: "far",
            to: RouteConstants.TRAINERS,
            quickLink: true,
            visible: isIntlAdmin,
          },
        ],
      },
    ],
  };

  return (
    <>
      <ChangeLanguageModal open={showChangeLanguage} setOpen={setShowChangeLanguage} />
      <NavigationV2 config={config} />
    </>
  );
};

export default Navigation;
